import { initPersistentIdentifier } from './persistentIdentifier';
try {
    initPersistentIdentifier();
} catch (e) {
    console.log(e);
}

// SETUP GTAG
(function (d) {
    var beginTime = new Date();
    var file = "https://www.googletagmanager.com/gtag/js?id=" + window.ga_account_id;
    var ref = d.getElementsByTagName('script')[0];
    var js = d.createElement('script');
    js.src = file;
    js.async = true;
    ref.parentNode.insertBefore(js, ref);
}(document));

window.dataLayer = window.dataLayer || [];
function gtag() {
    dataLayer.push(arguments);
}
gtag('js', new Date());
gtag('config', window.ga_account_id, {
    'site_speed_sample_rate': 100,
    'linker': {
        'accept_incoming': true
    }
});

// SETUP FB
!function (f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
    n.queue = []; t = b.createElement(e); t.async = !0;
    t.src = v; s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
}(window, document, 'script',
    'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', window.fb_id);
fbq('track', 'PageView');

// window.advertiser_id = 104;
// window.landing_page_event = 10401;
// window.add_to_cart_event = 10402;
// window.initiate_checkout_event = 10403;
// window.shipping_info_event = 10404;
// window.presell_landing_event = 10405;
// window.vsl_landing_event = 10406;
// window.ga_account_id = 'UA-58307713-6';
// window.fb_id = '2021080771452894';
// window.brand_name = 'FIXD';
// window.is_mor = 0;


const PublicTrackingHelpers = {

};


const TrackingManager = {
    initRetryCount: 0,
    initTracking: function () {
        try {

            TrackingManager.populateOfferGuParams();
            gu_fire_event("Page Start");
            TrackingManager.clearEventQ();
        } catch (e) {
            console.log(e);
        }
    },
    initGtag: function () {


    },
    clearEventQ: function () {
        try {
            if (window.guEventQ && window.guEventQ.length > 0) {
                for (var i = 0; i < window.guEventQ.length; i++) {
                    var event = window.guEventQ[i];
                    gu_fire_event(event.eventName, event.action, event.identifier, event.propertyName, event.propertyValue);
                }
                window.guEventQ = [];
            }
        } catch (e) {
            console.log(e);
        }
    },
    populateOfferGuParams: function () {
        try {
            if (!window.gu_qs) { window.gu_qs = {}; }
            if (!window.note_attributes) { window.note_attributes = {}; }
            window.gu_qs["br"] = window.brand_name;
            window.gu_qs["imor"] = window.is_mor;
            window.note_attributes['gu_qs'] = window.gu_qs_to_str(window.gu_qs);
        } catch (e) {
            console.log(e);
        }
    },

    fireLandingPageEvent: function () {
        try {
            var path = window.location.pathname.toLowerCase();
            if (path.startsWith('/splash')) {
                window.gu_fire_event("Splash Page");
                TrackingManager.fireEverflowPixel(window.site_vars.advertiser_id, window.site_vars.vsl_landing_event);
            }
            else if (path.startsWith('/blog')) {
                window.gu_fire_event("Presell Page");
                TrackingManager.fireEverflowPixel(window.site_vars.advertiser_id, window.site_vars.presell_landing_event);

            } else {
                window.gu_fire_event("Landing Page");
                TrackingManager.fireEverflowPixel(window.site_vars.advertiser_id, window.site_vars.landing_page_event);
            }
        } catch (e) {
            console.log(e);
        }
    },
    firePageStartEvent: function () {
        try {
            var path = window.location.pathname.toLowerCase();
            if (path.startsWith('/splash')) {
                window.gu_fire_event("Splash Page Start");
                TrackingManager.fireEverflowPixel(window.site_vars.advertiser_id, window.site_vars.vsl_landing_start_event);
            }
            else if (path.startsWith('/blog')) {
                window.gu_fire_event("Presell Page Start");
                TrackingManager.fireEverflowPixel(window.site_vars.advertiser_id, window.site_vars.presell_landing_start_event);

            } else {
                window.gu_fire_event("Landing Page Start");
                TrackingManager.fireEverflowPixel(window.site_vars.advertiser_id, window.site_vars.landing_page_start_event);
            }
        } catch (e) {
            console.log(e);
        }
    },
    fireAddToCartEvent: function () {
        TrackingManager.fireEverflowPixel(window.site_vars.advertiser_id, window.site_vars.add_to_cart_event);
    },

    fireShippingInfoEvent: function () {
        TrackingManager.fireEverflowPixel(window.site_vars.advertiser_id, window.site_vars.shipping_info_event);
    },

    fireInitiateCheckoutEvent: function () {
        TrackingManager.fireEverflowPixel(window.site_vars.advertiser_id, window.site_vars.initiate_checkout_event);
    },

    fireClickOffsitePaymentEvent: function () {
        window.gu_fire_event("Shipping Info");
    },

    fireEverflowPixel: function (advertiser, adv_event) {
        try {
            if (window.EF) {
                var efpixel = {
                    aid: advertiser,
                    adv_event_id: adv_event,
                    nid: 373
                };
                if (window.gu_qs && window.gu_qs['req_id']) {
                    efpixel.transaction_id = window.gu_qs['req_id'];
                }
                window.EF.conversion(efpixel);
            } else {
                var transactionid = '';
                if (window.gu_qs && window.gu_qs['req_id']) {
                    transactionid += gu_qs['req_id'];
                }
                var script = document.createElement('iframe');
                script.width = 1;
                script.height = 1;
                script.frameBorder = 0;
                script.src = "https://www.gu-ecom.com/?nid=373&advid=" + advertiser + "&adv_event_id=" + adv_event + "&transaction_id=" + transactionid;
                document.getElementsByTagName('script')[0].appendChild(script);
            }

        } catch (e) {
            console.log(e);
        }
    },

    fireRedshiftEvent: function (eventData) {
        try {
            var url = 'https://bsgfni3xkk.execute-api.us-west-2.amazonaws.com/production/e';
            var xhr = new XMLHttpRequest();
            xhr.open("POST", url, true);
            xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
            xhr.onload = function () {

            }
            var json = JSON.stringify(eventData);
            xhr.send(json);

        } catch (e) {
            console.log(e);
        }
    },
    fireAbTastyEvent: function (event) {
        try {
            if (window['guExperiment']) {
                window['guExperiment'].fireEvent(event);
            }
            else {
                window['guEEQ'] = window['guEEQ'] || [];
                window['guEEQ'].push(event);
            }

        } catch (e) {
            console.log(e);
        }
    },
    fireGaEvent: function (eventProperties) {
        try {
            console.log("fire ga event:" + eventProperties.eventName);
            var gaProperties = TrackingManager.buildGaEventProperties(eventProperties);
            gtag('event', eventProperties.eventName, gaProperties);
            TrackingManager.fireGaVirtualPageView(eventProperties.eventName);
        } catch (e) {
            console.log(e);
        }
    },
    fireGaVirtualPageView: function (eventName) {
        try {
            if (TrackingManager.gaVirtualPathMap && TrackingManager.gaVirtualPathMap[eventName]) {
                gtag('event', 'page_view',
                    {
                        page_path: TrackingManager.gaVirtualPathMap[eventName],
                        page_title: 'VPV - ' + eventName,
                        non_interaction: true
                    });
            }
        } catch (e) {
            console.log(e);
        }
    },
    triggerEfEvent: function (eventName) {
        try {
            if (eventName == 'Initiate Checkout') {
                TrackingManager.fireInitiateCheckoutEvent();
            } else if (eventName == 'Shipping Info') {
                TrackingManager.fireShippingInfoEvent();
            } else if (eventName == 'Select Bundle') {
                TrackingManager.fireAddToCartEvent();
            } else if (eventName == 'Page View') {
                TrackingManager.fireLandingPageEvent();
            } else if (eventName == 'Page Start') {
                TrackingManager.firePageStartEvent();
            }
        } catch (e) {
            console.log(e);
        }
    },

    fireFacebookEvent: function (fbevent, fbargs) {
        if (fbevent !== undefined) {
            if (fbargs !== undefined) {
                fbq('track', fbevent, fbargs);
            } else {
                fbq('track', fbevent);
            }
        }
    },

    gaVirtualPathMap: {
        "GalleryChanged": "/creative/gallery-changed/",
        "ShowFAQs": "/faq/show/",
        "OpenFAQ": "/faq/open/",
        "CloseFAQ": "/faq/close/",
        "Testimonials": "/testimonials/",

        "Yes To Inline Upsell": "/promotions-upsell/upsell-inline-click-yes/",
        "No To Inline Upsell": "/promotions-upsell/upsell-inline-click-no/",
        "Remove Inline Upsell": "/promotions-upsell/remove-upsell-inline-click/",
        "UpsellReject": "/promotions-upsell/upsell-reject/",
        "UpsellAccept": "/promotions-upsell/upsell-accept/",
        "No To Pop Sale": "/promotions-upsell/upsell-popup-click-no/",
        "Change Color Selection": "/promotions-upsell/color-selector-click/",

        "Page Start": "/page-view/start/",
        "Landing Page Start": "/landing-page/start/",
        "Presell Page Start": "/presell-page/start/",
        "Splash Page Start": "/splash-page/start/",

        "Page View": "/page-view/default/",
        "Landing Page": "/landing-page/default/",
        "Presell Page": "/presell-page/default/",
        "Splash Page": "/splash-page/default/",
        "Select Bundle": "/promotions/bundle-click",
        "Click PayPal": "/checkout/go-to-paypal/",
        "Shipping Info": "/checkout/shipping-info",
        "Initiate Checkout": "/checkout/initiate-checkout/",

        "View Shipping Page": "/checkout/view-shipping-page/",
        "View Credit Card Page": "/checkout/view-credit-card-page/",
        "Submit Order": "/checkout/submit-order/",

        "Incorrect State Input": "/errors/incorrect-state-input/",
        "Retrying Shipping Server": "/errors/retry-shipping-server/",
        "Retrying Server Connection": "/errors/retry-server-connection/",
        "Retrying Shipping Connection": "/errors/retry-shipping-connection/",
        "Credit Card Failed": "/errors/credit-card-failed/",
        "Error Updating Cart": "/errors/cart-update-failed/",
        "Internet or Shopify Down": "/errors/shopify-down/",
        "Error Processing Order": "/errors/order-processing-failed/",
        "Success Updating Cart": "/checkout/update-cart-success/",

    },
    buildGaEventProperties: function (eventProperties) {
        try {
            var gaProperties = {
                'event_category': "Giddyup OTM",
                'non_interaction': false,
                'event_label': eventProperties.propertyName,
                'value': eventProperties.propertyValue,
                'page_path': eventProperties.pagePath
            };
            if (typeof (eventProperties.propertyName) !== 'undefined') {
                gaProperties.event_label = eventProperties.propertyName
            }
            if (typeof (eventProperties.propertyValue) !== 'undefined') {
                gaProperties.value = eventProperties.propertyValue
            }
            if (typeof (eventProperties.pagePath) !== 'undefined') {
                gaProperties.page_path = eventProperties.pagePath
            }
            if (eventProperties.eventName == "Presell Page" || eventProperties.eventName == "Splash Page" || eventProperties.eventName == "Landing Page" || eventProperties.eventName == "Presell Page Start" || eventProperties.eventName == "Splash Page Start" || eventProperties.eventName == "Landing Page Start" || eventProperties.eventName == 'Page View' ||  eventProperties.eventName == 'Page Start' ) {
                gaProperties.non_interaction = true;
            }
            if (TrackingManager.gaVirtualPathMap[eventProperties.eventName]) {
                gaProperties.page = TrackingManager.gaVirtualPathMap[eventProperties.eventName];
            }
            return gaProperties;

        } catch (e) {
            console.log(e);
            return {};
        }
    },
    buildAbTastyEventProperties: function (eventProperties) {
        try {
            var data = {
                category: eventProperties.category || 'Giddyup',
                action: eventProperties.eventName,
                label: eventProperties.propertyName || '',
                value: eventProperties.propertyValue || '',
                pageTitle: eventProperties.title,
                documentLocation: eventProperties.pagePath
            }
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }
};


window.gu_fire_event = function (eventName, action, identifier, propertyName, propertyValue, pagePath) {
    try {
        var eventProperties = { 'action': action, 'identifier': identifier };
        for (var e in gu_qs) {
            eventProperties[e] = gu_qs[e]
        }
        eventProperties["url"] = document.baseURI;
        eventProperties["title"] = document.title;
        eventProperties["offer"] = getDomain(); //lpDomain
        eventProperties["domain"] = document.location.hostname;
        eventProperties["page"] = document.location.pathname;
        eventProperties["offerPage"] = document.location.pathname;//constant throught shopify
        eventProperties["mobileDevice"] = mobileDevice;
        eventProperties["pageType"] = 'Landing Page'; //preSellPage
        eventProperties["eventName"] = eventName;
        eventProperties["propertyName"] = propertyName;
        eventProperties["propertyValue"] = propertyValue;
        eventProperties["pagePath"] = pagePath;
        if (!eventProperties["referringSite"]) {
            eventProperties["referringSite"] = document.referrer;
        }
        if (!eventProperties["landingSite"]) {
            eventProperties["landingSite"] = window.location.href;
        }
        if (!eventProperties["gu_qs"]) {
            eventProperties["gu_qs"] = JSON.stringify(gu_qs);
        }

        if (eventName == "Order Conversion") {
            eventProperties["order"] = checkout;
            eventProperties["brand"] = '{{Offer - Brand Name}}';
            TrackingManager.populateOfferGuParams();
        } else if (eventName == "Initiate Checkout") {
            fbq('track', 'InitiateCheckout');
        } else if (eventName == "Select Bundle") {
            fbq('track', 'AddToCart');
        } else if (eventName == "GoToOfferSection" || eventName == "Go To Offer Section") {
            fbq('track', 'Lead');
        } else {

        }
        console.log(eventProperties);
        TrackingManager.triggerEfEvent(eventName);

        TrackingManager.fireGaEvent(eventProperties);

        var abTastyEvent = TrackingManager.buildAbTastyEventProperties(eventProperties);
        TrackingManager.fireAbTastyEvent(abTastyEvent);

        if (eventName != "Order Conversion" && eventName != "Page View") {
            TrackingManager.fireRedshiftEvent(eventProperties);
        }
    } catch (e) {
        console.log(e);
    }
}


window['guTrackingUtils'] = PublicTrackingHelpers;
TrackingManager.initTracking();
export { TrackingManager };